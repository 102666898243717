.bgContainer {
}

.topHeader {
  /* @apply bg-primary text-white rounded-t-lg  h-[10%] py-6 px-6 uppercase font-semibold text-2xl flex justify-between items-center */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 12px 24px;
  text-transform: uppercase;
  font-size: 1.5rem /* 24px */;
  line-height: 2rem /* 32px */;
}

.bodyHeader {
  /* @apply flex justify-between items-center mb-3 px-6 gap-8 */
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 12px;
}

.bodyHeader > div {
  /* @apply font-bold mt-3 */
  margin-top: 0.75rem /* 12px */;
  font-weight: 700;
}

.bodyContent {
  /* @apply px-3 my-3 overflow-scroll  h-[80%]; */
  padding-left: 20px;
  padding-right: 20px;
  overflow: scroll;
  height: 78%;
}
.bodyContent-2 {
  /* @apply px-3 my-3 overflow-scroll  h-[80%]; */
  padding-left: 20px;
  padding-right: 20px;
  overflow: scroll;
  height: 82%;
}

.item {
  /*  @apply bg-slate-200 mb-2 rounded-sm px-3 py-3 flex justify-between align-middle gap-8 */
  background-color: rgb(226 232 240 / 20);
  padding: 12px;
  margin-bottom: 0.5rem /* 8px */;
}
