.nav.nav-tabs .nav-link.active {
    background-color: "red"
}

.custom-shadow {
    box-shadow: 1px 3px 10px hsl(0, 0%, 70%);
}
.custom-shadow-sm {
    box-shadow: 1px 1px 5px hsl(0, 0%, 70%);
}
.custom-shadow-lg {
    box-shadow: 0px 0px 5px hsl(235, 95%, 62%)
}

.custom-border-radius {
    border-radius: 10px;
}
.custom-border-radius-sm {
    border-radius: 5px;
}

.flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
}


.scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
  
  /* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

.bg-primary2 {
    background: #f0f5f9;
}

.bg-gray {
    background: #e6e6e6;
}
  